import React, {Component} from 'react'
import './Recurring.css'

class Recurring extends Component {

    render() {
        return (
            <div className="Recurring">
                Recurring Flights
                <ol>
                    <li>Time Callsign Origin Destination</li>
                    <li>Time Callsign Origin Destination</li>
                    <li>Time Callsign Origin Destination</li>
                    <li>Time Callsign Origin Destination</li>
                </ol>
            </div>
        )
    }
}

export default Recurring
