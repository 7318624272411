import React, { Component } from 'react'
import './Footer.css'

class Footer extends Component {
  render() {
    return (
      <div className="Footer" style={{marginTop: "auto"}}>
        <div>
          <input type="checkbox" id="radar" checked={this.props.showRadar} onClick={(e) => this.props.toggleShowRadar(e)} />
          <label for="radar">Radar</label>
        </div>
        <div>
          <input type="checkbox" id="waterfall" checked={this.props.showWaterfall} onClick={(e) => this.props.toggleShowWaterfall(e)} />
          <label for="waterfall">Waterfall</label>
        </div>
        <div>
          <input type="checkbox" id="flightlist" checked={this.props.showList} onClick={(e) => this.props.toggleShowList(e)} />
          <label for="flightlist">List</label>
        </div>
        <div>
          <input type="checkbox" id="recurring" checked={this.props.showRecurring} onClick={(e) => this.props.toggleShowRecurring(e)} />
          <label for="recurring">Recurring</label>
        </div>
        <div>
          <input type="checkbox" id="voice" checked={this.props.voiceAnnounce} onClick={(e) => this.props.toggleVoiceAnnounce(e)} />
          <label for="voice">Voice</label>
        </div>
      </div>
    )
  }
}

export default Footer
