import React, { Component } from 'react'
import './Navbar.css'

class Navbar extends Component {
  render() {
    return (
      <div className="Navbar" >
        <ul >
          <li >Flight Watch</li>
          <li>
            <a
              href="https://www.tcmlabs.com"
              target="_blank"
              rel="noopener"
            >
              TCM Labs
              </a>
          </li>
        </ul>
      </div>
    )
  }
}

export default Navbar
