// import { render } from '@testing-library/react'
import React, { Component, createRef } from 'react'

/***********************************************/

// reticle canvas square is 0.450 of min window dimension
const RETICLE_CANVAS_OF_WINDOW = 0.450
// retticle crosshair radius is 0.9 of center to side
const RETICLE_FRACTION_OF_CANVAS = 0.9
// outer ring of reticle radius is 0.9 of crosshairs
const RETICLE_CIRCLE_OF_CROSSHAIRS = 0.9

const GRAPH_ORDINARY_RADIUS = 5

const GRAPH_INDICATING_RADIUS = 10
const INDICATING_COLOR = "snow"

// const SWEEP_MILLISECONDS = 2000
const SWEEP_MILLISECONDS = 0

const RADIUS_MIN_KM = 5
const RADIUS_MAX_KM = 100
const RADIUS_STEP_KM = 5
const MOUSE_WHEEL_STEP = 3

/***********************************************/

class Reticle extends Component {

    /*
     * props received from ^ThreatList, watched in componentDidUpdate()
     * -- every 2sec, threats = [ [icao, flight_data] ]
     * -- indicating := "none"|"{icao}", if something should be highlighted
     * 
     * events lifted to ^ThreatList:
     * -- indicating(icao)
     * -- handleReticleClick(e): together with indicting, opens new window
     *    with selected flight's details
     * 
     * React State: none -- state managed in ^ThreatList above
     * 
     * Uses one main/visible canvas by react reference
     * Maintains three invisible canvases: Reticle, Flights, and SweeperArm
     * -- Reticle updated on window resize
     * -- Flights updated every 2sec with new props; updates icaoPlot map
     * -- Sweeper updates every pass through the animation loop
     * Animation loop drops the three invisble canvases onto the visible canvas in sequence
     * -- [should probably be three z-indexed / stacked visible frames, but this
     *     is an intermediate step.]
     */

    constructor(props) {

        super(props)

        this.state = {
            // threats: props.threats,
            // icaoPlot: {}
        }

        // map of icao value to flight's plotted coords on canvas
        this.icaoPlot = {}
        // reference to the primary canvas object we'll draw on
        this.canvasRef = createRef()
        // routine binding of handlers methods
        this.handleResize = this.handleResize.bind(this)
        this.onRadiusChange = this.onRadiusChange.bind(this)
        this.handleMouseMove = this.handleMouseMove.bind(this)
        this.handleMouseLeave = this.handleMouseLeave.bind(this)
        this.handleIndicated = this.handleIndicated.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleMouseWheel = this.handleMouseWheel.bind(this)

        this.drawReticleCanvas = this.drawReticleCanvas.bind(this)
        this.drawSweepArmCanvas = this.drawSweepArmCanvas.bind(this)
        this.drawFlightsCanvas = this.drawFlightsCanvas.bind(this)

        this.animateReticle = this.animateReticle.bind(this)
    }

    /***********************************************/

    // LIFECYCLE

    componentDidMount() {
        window.addEventListener("resize", this.handleResize)

        this.canvasRef.current.addEventListener('wheel', this.handleMouseWheel)

        // Reticle only redraws on window resize
        this.canvasReticle = document.createElement("canvas")
        // Flights canvas only redraws with new push (threat_dtg) or indicating or radius
        this.canvasFlights = document.createElement("canvas")
        // sweep arm canvas updates continuously (while this.animate)
        this.canvasSweepArm = document.createElement("canvas")

        // the initial sizes and canvas drawing
        this.handleResize()

        // animation loop-continue condition
        this.animate = false
        if (SWEEP_MILLISECONDS > 0) {
            this.animate = true
        }

        // kick the animation sequence
        requestAnimationFrame(this.animateReticle)
    }

    componentWillUnmount() {
        this.animate = false

        this.canvasRef.current.removeEventListener('wheel', this.handleMouseWheel)
        window.removeEventListener("resize", this.handleResize)
    }

    componentDidUpdate(prevProps) {

        // only update the flight canvas if there's been a new websocket push
        // or if user selects / deselects interest in a flight
        if (
            prevProps.threats_dtg !== this.props.threats_dtg
            || prevProps.indicating !== this.props.indicating
            || prevProps.radius !== this.props.radius
        ) {
            // console.log("componentDidUpdate():", this.props.threats_dtg)
            // requestAnimationFrame(() => { this.drawFlightsCanvas() })
            this.drawFlightsCanvas()
            if (!this.animate) {
                this.animateReticle()
            }
        }
    }

    /***********************************************/

    // EVENT HANDLERS

    // user is selecting/deselecting interest in a flight in this view
    handleIndicated(icao) {
        this.props.handleIndicated(icao)
    }

    // we own the input range slider, but lift state to ThreatList container
    // (value will return as props)
    onRadiusChange(radius) {
        this.props.handleRadiusChange(radius)
    }

    handleMouseWheel(e) {
        // on my mouse, a wheel click is 3 units ~ fwiw
        // range slider moves by 5 between 5km & 100km
        e.preventDefault() // don't also scroll the page; just zoom here.
        var propose = this.props.radius + RADIUS_STEP_KM * e.deltaY / MOUSE_WHEEL_STEP
        if (propose < RADIUS_MIN_KM)
            propose = RADIUS_MIN_KM
        else if (propose > RADIUS_MAX_KM)
            propose = RADIUS_MAX_KM
        this.onRadiusChange(propose)
        return false
    }

    /***********************************************/

    handleResize(e) {

        const canvas = this.canvasRef.current

        // aesthetics, % of total window for the reticle canvas
        const scale = RETICLE_CANVAS_OF_WINDOW
        const side = Math.min(scale * window.innerHeight, scale * window.innerWidth)

        // set the visible square canvas dimensions
        canvas.height = side
        canvas.width = side

        // reset size of reticle canvas & draw it
        this.canvasReticle.width = side
        this.canvasReticle.height = side
        this.drawReticleCanvas()

        // reset size of sweep-arm canvas & draw it
        if (SWEEP_MILLISECONDS > 0) {
            this.canvasSweepArm.width = side
            this.canvasSweepArm.height = side
            this.drawSweepArmCanvas()
        }

        // reset the size of the flights canvas & draw it
        this.canvasFlights.width = side
        this.canvasFlights.height = side
        this.drawFlightsCanvas()
    }

    /***********************************************/

    // INVISIBLE CANVAS DRAWING

    drawFlightsCanvas() {

        const canvas = this.canvasFlights
        const context = canvas.getContext("2d")
        const centerX = canvas.width / 2
        const centerY = canvas.height / 2
        const minDim = RETICLE_FRACTION_OF_CANVAS * Math.min(canvas.width, canvas.height)
        const maxRadius = RETICLE_CIRCLE_OF_CROSSHAIRS * minDim / 2

        // context.save()

        context.clearRect(0, 0, canvas.width, canvas.height)

        /*
         * Drawing Parameters Used
         * =======================
         * lineWidth
         * strokeStyle
         * lineCap
         * filter = "brightness(p%)"
         */

        context.lineCap = "round"

        const newPlots = {}
        for (let [icao, flight] of this.props.threats) {

            const rr = flight.range / this.props.radius * maxRadius
            const theta = flight.bearing * Math.PI / 180
            const ipx = centerX + rr * Math.sin(theta)
            const ipy = centerY - rr * Math.cos(theta)

            // stash the raw context plot point for later flight indicating work
            newPlots[icao] = { x: ipx, y: ipy }

            // default flight "dot" will be a circle with this radius
            let point_radius = 5

            // if hovering on the list view, highlight on the radar view
            if (icao === this.props.indicating) {
                context.filter = "brightness(100%)"
                context.strokeStyle = INDICATING_COLOR
                point_radius = GRAPH_INDICATING_RADIUS

                // console.log(`Indicating. filter=${context.filter}, strokeStyle=${context.strokeStyle}, lineWidth=${context.lineWidth}, point_radius=${point_radius}`)
            }
            else {
                context.filter = "brightness(" + flight.brightness + "%)"
                context.strokeStyle = flight.color
                point_radius = GRAPH_ORDINARY_RADIUS
            }

            // draw the "dot"
            const b_radius = (flight.ground_speed - 150) / 50 + point_radius
            const b_theta = flight.track * Math.PI / 180

            context.lineWidth = 2
            context.beginPath()
            // rotate left 90-deg for coordinate system, then open up 45-deg on both sides
            context.arc(ipx, ipy, point_radius, b_theta - 0.79, b_theta + Math.PI + 0.79)
            context.stroke()

            // draw a pointer in the direction of travel, length indicating ground speed
            // make tail at least 3px longer than the "dot" radius, plus some for ground speed
            // (ground speed is typically 150-350 kts)
            context.lineWidth = 2
            context.beginPath()
            context.moveTo(ipx, ipy)
            context.lineTo(ipx + b_radius * Math.sin(b_theta), ipy - b_radius * Math.cos(b_theta))
            context.stroke()
            // reset coordinates and rotation for the next plot
        }

        this.icaoPlot = newPlots
        // this.setState({ icaoPlot: newPlots })

        // context.restore()
    }


    drawSweepArmCanvas(color = "lime") {

        if (SWEEP_MILLISECONDS <= 0) return

        const canvas = this.canvasSweepArm
        const context = canvas.getContext("2d")
        const centerX = (canvas.width / 2)
        const centerY = (canvas.height / 2)
        const minDim = 0.9 * (Math.min(canvas.width, canvas.height))

        context.clearRect(0, 0, canvas.width, canvas.height)

        const time = new Date()
        const theta = 2 * Math.PI * (1000 * time.getSeconds() + time.getMilliseconds()) / SWEEP_MILLISECONDS
        const radius = minDim / 2

        context.strokeStyle = color
        context.lineWidth = 3

        context.beginPath()
        context.moveTo(centerX, centerY)
        context.lineTo(
            centerX + radius * Math.cos(theta),
            centerY + radius * Math.sin(theta)
        )
        context.stroke()
    }


    drawReticleCanvas(color = "green") {

        const canvas = this.canvasReticle
        const context = canvas.getContext("2d")

        const centerX = (canvas.width / 2)
        const centerY = (canvas.height / 2)
        // draw within a square. determine the min length side of area, leaving some space
        const minDim = 0.9 * (Math.min(canvas.width, canvas.height))
        // ... and pull in the outer ring a bit for aesthetics
        const maxRadius = (0.9 * minDim / 2)

        // clear screen
        context.fillStyle = "black"
        context.fillRect(0, 0, canvas.width, canvas.height)
        // context.clearRect(0, 0, canvas.width, canvas.height)

        // set pen for reticle
        context.lineCap = "round"
        context.strokeStyle = color
        context.lineWidth = 5
        // outer circle
        context.beginPath()
        context.arc(centerX, centerY, maxRadius, 0, 2 * Math.PI)
        context.stroke()
        // inner circle
        context.beginPath()
        context.arc(centerX, centerY, 0.5 * maxRadius, 0, 2 * Math.PI)
        context.stroke()
        // verticle line
        context.beginPath()
        context.moveTo(centerX, centerY - (minDim / 2))
        context.lineTo(centerX, centerY + (minDim / 2))
        context.stroke()
        // horizontal line
        context.beginPath()
        context.moveTo(centerX - (minDim / 2), centerY)
        context.lineTo(centerX + (minDim / 2), centerY)
        context.stroke()
    }

    /***********************************************/

    handleClick(e) {
        // the position and nearest indicating icao is already set (handleMouseMove())
        // only need to report to the parent ThreatList that the click occured
        this.props.handleReticleClick(e)
    }

    // mouse leaving the radar view deselects any selected flights
    handleMouseLeave(e) {
        this.props.handleIndicated("none")
    }

    // as the mouse moves over the radar view, select the nearest flight plotted
    handleMouseMove(e) {

        // get the canvas right away, and do some quick calculations
        const canvas = this.canvasRef.current

        let rect = canvas.getBoundingClientRect()
        let mouseX = e.clientX - rect.left
        let mouseY = e.clientY - rect.top

        var best_distance = -1
        var best_icao = ""

        const tmp_flights = this.props.threats.filter(([icao, flight]) => { return flight.range <= this.props.radius })

        for (let [icao, flight] of tmp_flights) {
            if (!this.icaoPlot[icao]) continue
            let hh = Math.hypot((mouseX - this.icaoPlot[icao].x), (mouseY - this.icaoPlot[icao].y))
            // let hh = Math.hypot((mouseX - flight.plot_x), (mouseY - flight.plot_y))
            if (best_distance === -1 || hh < best_distance) {
                best_distance = hh
                best_icao = icao
            }
        }

        // test scaffolding: draw lines to points to verify
        if (false && best_distance !== -1) {

            requestAnimationFrame(() => {
                const ctx = canvas.getContext("2d")
                ctx.save()
                ctx.strokeStyle = "cyan"
                ctx.lineCap = "round"
                ctx.lineWidth = 2

                for (let [icao, flight] of tmp_flights) {
                    ctx.beginPath()
                    if (icao === best_icao) {
                        ctx.strokeStyle = "red"
                    }
                    else {
                        ctx.strokeStyle = "cyan"
                    }
                    ctx.moveTo(mouseX, mouseY)
                    // ctx.lineTo(flight.plot_x, flight.plot_y)
                    ctx.lineTo(this.state.icaoPlot[icao].x, this.state.icaoPlot[icao].y)
                    ctx.stroke()
                }
                ctx.restore()
            }) // animation frame
        }

        this.props.handleIndicated(best_icao)
    }

    /***********************************************/

    render() {
        // canvas, above radius range slider, above slider value
        // radius state is lifted to ThreatList container, comes back as prop
        // (SHOULD set range parameters as constants / environment variables)
        return (
            <div>
                <div>
                    <canvas
                        ref={this.canvasRef}
                        onMouseMove={e => this.handleMouseMove(e)}
                        onMouseLeave={e => this.handleMouseLeave(e)}
                        onClick={e => this.handleClick(e)}
                    />
                </div>
                <div>
                    <input
                        type="range"
                        min={RADIUS_MIN_KM}
                        max={RADIUS_MAX_KM}
                        step={RADIUS_STEP_KM}
                        value={this.props.radius}
                        onChange={({ target: { value: radius } }) => {
                            this.onRadiusChange(radius)
                        }}
                    />
                </div>
                <div>
                    {this.props.radius} km
                </div>
            </div>

        );
    }

    /***********************************************/

    /*
     * Animation Loop
     * -- reticle base
     * -- sweep arm above
     * -- flights last
     * 
     * each layer is stored on its own canvas,
     * which in turn is updated only when necessary.
     * Here, they're all drawn on the man canvas.
     * 
     * These probably should be z-indexed...
     */


    animateReticle() {
        // first run may not have canvas set yet...
        if (!this.canvasRef.current) return

        const canvas = this.canvasRef.current
        const context = canvas.getContext("2d")

        context.drawImage(this.canvasReticle, 0, 0)

        if (SWEEP_MILLISECONDS > 0) {
            this.drawSweepArmCanvas()
            context.drawImage(this.canvasSweepArm, 0, 0)
        }

        context.drawImage(this.canvasFlights, 0, 0)

        if (this.animate)
            requestAnimationFrame(() => this.animateReticle(context))
    }

} // class component

export default Reticle;
