import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import ThreatList from './ThreatList'
import Navbar from './Navbar'
import Footer from './Footer'

import { useState } from 'react'

function App() {

  const [showRadar, setShowRadar] = useState(true)
  const [showWaterfall, setShowWaterfall] = useState(true)
  const [showList, setShowList] = useState(true)
  const [showRecurring, setShowRecurring] = useState(false)
  const [voiceAnnounce, setVoiceAnnounce] = useState(false)

  function toggleShowRadar(e) {
    console.log("toggle event:", e)
    setShowRadar(!showRadar)
  }

  function toggleShowWaterfall(e) {
    console.log("toggle event:", e)
    setShowWaterfall(!showWaterfall)
  }

  function toggleShowList(e) {
    console.log("toggle event:", e)
    setShowList(!showList)
  }

  function toggleShowRecurring(e) {
    console.log("toggle event:", e)
    setShowRecurring(!showRecurring)
  }

  function toggleVoiceAnnounce(e) {
    console.log("toggle event:", e)
    setVoiceAnnounce(!voiceAnnounce)
  }

  return (
    <div className="App">

      <Navbar />

      <ThreatList
        showRadar={showRadar}
        showWaterfall={showWaterfall}
        showList={showList}
        showRecurring={showRecurring}
        voiceAnnounce={voiceAnnounce}
      />

      <Footer
        showRadar={showRadar}
        showWaterfall={showWaterfall}
        showList={showList}
        voiceAnnounce={voiceAnnounce}
        toggleShowRadar={toggleShowRadar}
        toggleShowWaterfall={toggleShowWaterfall}
        toggleShowList={toggleShowList}
        toggleShowRecurring={toggleShowRecurring}
        toggleVoiceAnnounce={toggleVoiceAnnounce}
      />

    </div>
  );
}

export default App;
