import React, { Component } from 'react'
import { Card } from 'react-bootstrap'
import './Flight.css'

// Icon selections in material design: https://material.io/resources/icons/?style=baseline
import { Icon, InlineIcon } from "@iconify/react";
import takeoff from "@iconify/icons-mdi/airplane-takeoff";
import landing from "@iconify/icons-mdi/airplane-landing";
import arrow from "@iconify/icons-mdi/arrow-right";
import same from "@iconify/icons-mdi/help-circle-outline";
import acuteAngle from "@iconify/icons-mdi/angle-acute";
import departing from "@iconify/icons-mdi/chevron-right";
import approaching from "@iconify/icons-mdi/chevron-left";


class Flight extends Component {

    constructor(props) {
        super(props)
        this.onMouseOut = this.onMouseOut.bind(this)
        this.onMouseOver = this.onMouseOver.bind(this)
    }

    onMouseOver(e) {
        const { target: { id: icao } } = e
        e.target.style.color = "snow"
        e.target.style.filter = "brightness(100%)"
        this.props.handleIndicated(icao)
    }

    onMouseOut(e) {
        const { target: { id: icao } } = e
        e.target.style.color = this.props.flight.color
        e.target.style.filter = "brightness(" + this.props.flight.brightness + "%)"
        this.props.handleIndicated("none")
    }


    render() {
        var styleProps = null
        // var styleProps = { "min-width": "500rem" }
        var flight = this.props.flight

        if (this.props.indicating) {
            styleProps = {
                color: "snow",
                filter: "brightness(100%)"
            }
        }
        else {
            styleProps = {
                color: flight.color,
                filter: "brightness(" + flight.brightness + "%)"
            }
        }


        // EXPERIMENTING HERE ***
        let direction
        if (flight.approaching) {
            direction = <InlineIcon icon={approaching} />
        }
        else {
            direction = <InlineIcon icon={departing} />
        }


        /*
         * This is unnecessarily specific...
         * SHOULD generalize, or rely on climb rate?
         */
        let symbol = <InlineIcon icon={arrow} />
        if (flight.origin === flight.destination)
            symbol = <InlineIcon icon={same} />
        else if (flight.origin === "BWI")
            symbol = <InlineIcon icon={takeoff} />
        else if (flight.destination === "BWI")
            symbol = <InlineIcon icon={landing} />

        return (
            <Card bg='dark' style={{ minWidth: "18rem", marginBottom:"15px" }}>
                    <Card.Header>
                        <a
                            id={flight.hex_ident}
                            style={styleProps}
                            className="Flight-link"
                            href={`https://flightaware.com/live/flight/${flight.callsign}`}
                            target="_"
                            onMouseOver={e => this.onMouseOver(e)}
                            onMouseOut={e => this.onMouseOut(e)}
                            title={flight.summary}
                        >
                            {flight.callsign.trim()}: {flight.origin} {symbol} {flight.destination}
                        </a>
                    </Card.Header>
                <Card.Body style={{padding: "0px 1.25rem"}}>
                    <Card.Text>
                        {flight.range.toFixed(3)} km <InlineIcon icon={acuteAngle} /> {Math.round(flight.bearing)} <InlineIcon icon={acuteAngle} /> {Math.round(flight.elevation)}
                        <br />{flight.ground_speed} kts <InlineIcon icon={acuteAngle} /> {flight.track} @ {flight.altitude}'
                    </Card.Text>
                </Card.Body>
            </Card >
        )
    } // render
} // Flight

export default Flight
