import React, { Component, createRef } from 'react'
import './RadarWaterfall.css'

class RadarWaterfall extends Component {

    constructor(props) {
        super(props)
        this.canvasRef = createRef()
        this.state = {
            history: []
        }
        this.handleResize = this.handleResize.bind(this)
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize)
        this.handleResize()
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize)
    }

    handleResize() {
        const canvas = this.canvasRef.current
        const scale = 0.450
        const side = scale * Math.min(window.innerHeight, window.innerWidth)
        canvas.width = side

        // console.log("waterfall canvas:", canvas.width, canvas.height)
    }

    componentDidUpdate(prevProps) {
        // new props means flight update vector received
        if (prevProps.threats_dtg !== this.props.threats_dtg) {
            this.state.history.push(this.props.threats)
            if (this.state.history.length > this.canvasRef.current.length)
                this.state.history.shift()
        }
    }

    render() {

        if (this.canvasRef.current) {
            // console.log("RadarWaterfall, render()")

            const canvas = this.canvasRef.current
            const ctx = canvas.getContext("2d")

            const hh = this.state.history
            const rows_history = hh.length
            const rows_visible = canvas.height
            const rows = Math.min(rows_history, rows_visible)

            function perc2color(perc) {
                var r, g, b = 0;
                if (perc < 50) {
                    r = 255;
                    g = Math.round(5.1 * perc);
                }
                else {
                    g = 255;
                    r = Math.round(510 - 5.10 * perc);
                }
                var h = r * 0x10000 + g * 0x100 + b * 0x1;
                return '#' + ('000000' + h.toString(16)).slice(-6);
            }

            function drawFlights(indicating) {

                ctx.fillStyle = "black"
                ctx.fillRect(0, 0, canvas.width, canvas.height)
                // ctx.clearRect(0, 0, canvas.width, canvas.height)

                ctx.strokeStyle = "white"
                ctx.lineWidth = 2
                ctx.beginPath()
                ctx.moveTo(0, 0)
                ctx.lineTo(canvas.width, 0)
                ctx.stroke()
                ctx.beginPath()
                ctx.moveTo(canvas.width / 2, 0)
                ctx.lineTo(canvas.width / 2, canvas.height)
                ctx.stroke()
                ctx.beginPath()
                ctx.moveTo(canvas.width / 4, 0)
                ctx.lineTo(canvas.width / 4, canvas.height)
                ctx.stroke()
                ctx.beginPath()
                ctx.moveTo(canvas.width * 3 / 4, 0)
                ctx.lineTo(canvas.width * 3 / 4, canvas.height)
                ctx.stroke()

                for (let ii = 0; ii < rows; ii++) {
                    const rr = rows_history - ii - 1
                    const threats = hh[rr]
                    for (let [icao, flight] of threats) {
                        const { callsign, range, bearing, color } = flight

                        let bb = Math.round(bearing)
                        if (bb > 180) bb = bb - 360
                        let xx = (bb + 180) * canvas.width / 360

                        // console.log(`ii=${ii}, callsign: ${callsign}, bearing: ${bearing}`)

                        // ctx.strokeStyle = "green"
                        if (indicating !== icao)
                            ctx.fillStyle = perc2color(range * 2)
                        else
                            ctx.fillStyle = "snow"
                        ctx.lineWidth = 2
                        ctx.beginPath()
                        ctx.fillRect(xx, ii, 2, 2)
                        ctx.stroke()
                    }
                } // history row

            } // drawFlights()

            requestAnimationFrame(() => { drawFlights(this.props.indicating) })
        }

        return (
            <div className="radar-waterfall">
                <canvas ref={this.canvasRef} />
            </div>
        )
    }
}


export default RadarWaterfall
