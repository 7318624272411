import React, { Component } from 'react'

import getDistanceFromLatLonInKm from './haversine'
import bearing from './bearing'
import elevation from './elevation'

import Reticle from './Reticle2'
import RadarWaterfall from './RadarWaterfall'
import Flight from './Flight'
import Recurring from './Recurring';

import Say from 'react-say';

import './ThreatList.css'
import { CardDeck, CardGroup } from 'react-bootstrap'

// const URL_API_FETCH = 'http://localhost:3000/api/current'
const URL_API_FETCH = 'https://flights.tcmlabs.com/api/current'
const FETCH_INTERVAL_MS = 3000

/*
 * ThreatList
 * - pulls all visible flights with complete records every n-sec
 * - maintains state for various views of the flights
 * 
 * Currently, display toggles are in App/Footer and sent down as props.
 * 
 * We maintain state from different views about which flight is indicated.
 * Also maintain the user-selected radius [5,100km] for what to display.
 * 
 * Basic unit: [icao, flight_record], where icao = flight_record.hex_ident.
 */

class ThreatList extends Component {

    constructor(props) {
        super(props)
        // initial state
        this.state = {
            threats: [],            // list received from server every 2sec
            radius: 50,             // reticle view outer circle (km)
            indicating: "none",     // icao value of user-selected flight for highlight
            threats_dtg: new Date(),
            client_location: {
                coords: {
                    latitude: 39.206090,
                    longitude: -76.848300
                }
            }
        }
        // routine handler binds
        this.handleRadiusChange = this.handleRadiusChange.bind(this)
        this.handleIndicated = this.handleIndicated.bind(this)
        this.handleReticleClick = this.handleReticleClick.bind(this)
    } // constructor

    // radius from Reticle component affects list and reticle views
    handleRadiusChange(radius) {
        this.setState({ radius })
    }

    handleIndicated(icao) {
        this.setState({ indicating: icao })
    }

    // on reticle click, open flight details page for selected flight
    // (as if clicking associated list hyperlink)
    handleReticleClick(e) {

        const threats = this.state.threats

        const icaoCallsign = threats.reduce((dict, [icao, flight]) => {
            dict[icao] = flight.callsign
            return dict
        }, {})

        const indicating = this.state.indicating
        const callsign = icaoCallsign[indicating]

        if (callsign)
            window.open(`https://flightaware.com/live/flight/${callsign}`, "_blank")
    }


    componentDidMount() {
        document.title = "Flight Watch - TCM Labs"

        // CLIENT GEOLOCATION DATA
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (location) => {
                    console.log("Client position:", location)
                    this.setState({ client_location: location })
                },
                (error) => {
                    console.log("No location info. Using default.", error)
                }
            )
        }

        // voice for announcements
        const synth = window.speechSynthesis
        const allVoices = synth.getVoices()
        // var voices = allVoices.filter(vv => vv.lang.startsWith("en-US"))

        // the next line is the safest, but I find en-US-DENIS best so far if available.
        // voices = synth.getVoices().filter(vv => vv.lang.startsWith("en-US"))
        const useVoice = allVoices.find(vv => vv.lang === "en-US-DENIS")
        // const useVoice = allVoices.find(vv => vv.lang === "en-US-MARIO")

        this.useVoice = useVoice

        // ALTERNATIVE PULL TO WEBSOCKET PUSH
        this.apiFetchInterval = setInterval(async () => {
            try {
                let olat = encodeURIComponent(this.state.client_location.coords.latitude)
                let olon = encodeURIComponent(this.state.client_location.coords.longitude)
                let orad = encodeURIComponent(this.state.radius)
                let apiq = `?lat=${olat}&lon=${olon}&rad=${orad}`
                let response = await fetch(URL_API_FETCH + apiq, { crossDomain: true })
                let flights = await response.json()

                // sourced data is { _id: icao, ...flight }.
                // transform to legacy [icao, flight]
                // (ok to carry the _id field as baggage)
                let records = flights.map(flight => [flight._id, flight])

                // legacy handling (from websockets push) starts here

                const filtered = records.filter(([icao, flight]) => {
                    // validate that the flight record sent is "complete"
                    // (SHOULD validate values received)
                    return flight.callsign
                        && flight.origin && flight.destination
                        && flight.range && flight.bearing
                })

                // augment the flights' data with display parameters
                let now = new Date()
                let announcements = []
                for (let [icao, flight] of filtered) {

                    let { range, last_seen, summary } = flight

                    /*
                     * CHANGE FRAME OF REFERENCE FROM SENSOR TO OBSERVER
                     *
                     * NB: The reticle view plots based on (range, bearing), not (lon,lat),
                     * so no need to change existing sub-components.
                     * Only need to recalculate range & bearing to each flight
                     */

                    let client = this.state.client_location.coords

                    flight.range = getDistanceFromLatLonInKm(
                        client.latitude, client.longitude,
                        flight.latitude, flight.longitude
                    )

                    flight.bearing = bearing(
                        client.latitude, client.longitude,
                        flight.latitude, flight.longitude
                    )

                    // TEST
                    flight.elevation = elevation(flight)

                    /*
                     * we can hear an inbound plane starting around 2.5km
                     * noise starts to fade after around 5km in the wake
                     * (SHOULD make these constants / environment variables)
                     */

                    let color = "lime"
                    if (range <= 2.5) {
                        color = "red"
                        if (summary) {
                            announcements.push([icao, summary])
                        }
                    }
                    else if (range <= 5.0) {
                        color = "yellow"
                    }

                    /*
                     * last_seen is a timestamp for last ADS-B message processed
                     * progressively fade the list and radar items
                     * (every 30sec, the server purges flights not seen in 1min)
                     */
                    const age_ms = now - new Date(last_seen)
                    const brightness = Math.max(100 - (age_ms / 2000) * 10, 40)

                    flight.color = color
                    flight.brightness = brightness
                } // flight loop for this message

                // trigger react updates with state change
                this.setState({
                    threats: filtered,
                    threats_dtg: new Date(),
                    announcements
                })
            }
            catch (err) {
                console.log("FETCH PULL ERROR:", err)
                // clearInterval(this.apiFetchInterval)
            }

        }, FETCH_INTERVAL_MS)

    } // componentDidMount()

    componentWillUnmount() {
        clearInterval(this.apiFetchInterval)
    }


    render() {

        // Pass the sorted list of flights within the selected radius to the components
        const displayFlightsList = this.state.threats.filter(([icao, data]) => {
            return data.range <= this.state.radius
        }).sort((a, b) => { // nearest to furthest
            return a[1].range - b[1].range
        })


        return (
            <div className="ThreatList">

                <div className="radar-column">
                    {
                        this.props.showRadar &&
                        <Reticle
                            threats_dtg={this.state.threats_dtg}
                            threats={displayFlightsList}
                            indicating={this.state.indicating}
                            radius={this.state.radius}
                            handleRadiusChange={this.handleRadiusChange}
                            handleIndicated={this.handleIndicated}
                            handleReticleClick={this.handleReticleClick}
                        />
                    }
                    {
                        this.props.showWaterfall &&
                        <RadarWaterfall
                            threats_dtg={this.state.threats_dtg}
                            threats={displayFlightsList}
                            indicating={this.state.indicating}
                        />
                    }
                </div>

                <div className="list-column">

                { this.props.showList && displayFlightsList.length > 0 &&
                    <CardDeck>{
                        displayFlightsList.map(rec => {
                            const [icao, data] = rec
                            return <Flight
                                key={data.callsign}
                                flight={data}
                                handleIndicated={this.handleIndicated}
                                indicating={icao === this.state.indicating}
                            />
                        })
                    }</CardDeck>
                }

                </div>

                {
                    this.props.showRecurring &&
                    <div className="recurring-column">
                        <Recurring />
                    </div>
                }

                <div >
                    {
                        this.props.voiceAnnounce &&
                        this.state.announcements &&
                        this.state.announcements.map(([icao, aa]) => {
                            return <Say key={icao} voice={this.useVoice} text={aa} />
                        })
                    }
                </div>

            </div>
        ) // return
    } // render
} // class ThreatList

export default ThreatList
