// Converts from radians to degrees.
function toDegrees(radians) {
    return radians * 180 / Math.PI;
}

// flight.altitude (barometric) is in feet. flight.range is in km
function elevation(flight) {
    const elevationRad = Math.atan2(flight.altitude, 1000.0 * flight.range * 3.28084)
    return toDegrees(elevationRad)
}


module.exports = elevation;
